import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import RequestHandler from '../../helpers/RequestHandler'
import "./StripeCardComponent.scss";

const StripeCardComponent = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  // const disableOrganization = props.disableOrganisation
  const userLevel = props.level
  const [formSubmit, setFormSubmit] = useState(false)
  const [organization, setOrganization] = useState('')
  const [validOrgError, setValidOrgError] = useState(null)
  const [validOrgSuccess, setValidOrgSuccess] = useState(null)
  const [cardErrorMessage, setCardErrorMessage] = useState(null)
  const [validatingOrg, setValidatingOrg] = useState(false)

  useEffect(() => {
    if (typeof props.subscriptionProcessing !== 'undefined' ) {
      setProcessing(props.subscriptionProcessing)
    }
    return () => true
  }, [props.subscriptionProcessing])

  const validateOrganizationName = (name) => {
    return RequestHandler.post('/api/validate-organization/', {
      name
    }).then((res) => res.data.data)
    .then((res) => {
      const { valid } = res
      if (!valid) {
        setValidOrgSuccess(null)
        setValidOrgError('The organization name is already exists.')
        return false
      } else {
        setValidOrgError(null)
        setValidOrgSuccess('The organization name is available.')
        return true
      }
    }).catch((e) => {
      setValidOrgSuccess(null)
      setValidOrgError('Internal server error. Please try after few minutes.')
      console.log(e)
      return false
    }).finally(() => setValidatingOrg(true))
  }

  const handleSubmit = async (event) => {
    setProcessing(true);
    setFormSubmit(true)
    setValidOrgError(null)
    setCardErrorMessage(null)
    setValidatingOrg(false)
    setValidOrgSuccess(null)
    // Block native form submission.
    event.preventDefault();
    // console.log('disableOrganization : ', props.disableOrg)
    if (props.disableOrg === false && organization.trim().length === 0) {
      setProcessing(false)
      return
    }

    if (!stripe || !elements) {
      setProcessing(false)
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (props.disableOrg === false) {
      const validOrganization = await validateOrganizationName(organization)
      if (!validOrganization) {
        setProcessing(false)
        return false
      }
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    try {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setProcessing(false);
        if (typeof props.handlePaymentMethodFailed !== "undefined") {
          setCardErrorMessage(error.message)
          props.handlePaymentMethodFailed({
            paymentMethod: null,
            stripe,
            error: error.message,
            card: cardElement,
          });
        }
      } else {
        setProcessing(false);
        if (typeof props.handlePaymentMethodSuccess !== "undefined") {
          let postObject = {
            paymentMethod,
            stripe,
            card: cardElement
          }
          if (!props.disableOrg) {
            postObject.organization = organization
          }
          props.handlePaymentMethodSuccess(postObject);
        }
      }
    } catch (e) {
      setProcessing(false);
      setCardErrorMessage(e.message)
      props.handlePaymentMethodFailed({
        paymentMethod: null,
        stripe,
        error: e.message,
        card: cardElement,
      });
    }
  };
  const organizationInvalid = formSubmit === true  && organization.trim().length === 0
  return (
    <div className="stripe-card-element-from">
      <Form className="my-0">
        {!props.disableOrg && !userLevel ? (
          <>
            <FormGroup>
              <Label for="organization">Organization {userLevel}*</Label>
              <Input type="text" name="organization" id="organization" placeholder="Please enter your desired organization name" onChange={async (e) => {
                const orgValue = e.target.value
                if (orgValue) {
                  await validateOrganizationName(orgValue)
                }
                setOrganization(e.target.value)
              }} invalid={validatingOrg && (organizationInvalid || validOrgError)} valid={validatingOrg && organization && (!validOrgError || !organizationInvalid) ? true : null} className="mb-2"/>
              {organizationInvalid ? (
                <FormFeedback className="mb-3 text-left">This field is required.</FormFeedback>
              ) : null}
              {validOrgError ? (
                <FormFeedback className="mb-3 text-left">{validOrgError}</FormFeedback>
              ) : null}
              {validOrgSuccess ? (
                <FormFeedback className="mb-3 text-left" valid>{validOrgSuccess}</FormFeedback>
              ) : null}
            </FormGroup>
          </>
        ) : null}
        <FormGroup>
          {props.cardLabel ? (
            <Label for="card_details" className={props.cardLableClass}>Card Details</Label>
          ) : null}
          <div className="card-form-input">
            <CardElement options={{
              hidePostalCode: true
            }}/>
          </div>
          {cardErrorMessage ? (
            <p className="text-danger mt-2 card-error">{cardErrorMessage}</p>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Button
            className="pay-button"
            onClick={handleSubmit}
            disabled={!stripe || processing === true || validOrgError}
          >
            {props.buttonText}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};
StripeCardComponent.defaultProps = {
  buttonText: 'Authorize Payment',
  disableOrg: false,
  subscriptionProcessing: () => true,
  handlePaymentMethodFailed: () => true,
  handlePaymentMethodSuccess: () => true,
  cardLabel: null,
  cardLableClass: null
}

export default StripeCardComponent;
