import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOGIN_ERROR,
  INVITE_USER,
  INVITE_SUCCESS,
  INVITE_ERROR,
  LOGOUT_LOGGEDIN_USER,
  FORGOT_PASS,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_ERROR,
  CHANGE_PASS,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_ERROR,

  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,
  RESET_STATE,
} from "./actionTypes";

import RequestHandler from "../../../helpers/RequestHandler";

import { REGISTER_OAOUTH_USER } from "../register/actionTypes";

/**
 * Resend mail verification ....
 */

const resendVerfication = () => {
  return {
    type: RESEND_CONFIRMATION,
  };
};

const resendVerficationSuccess = (msg) => {
  return {
    type: RESEND_CONFIRMATION_SUCCESS,
    payload: msg,
  };
};

const resendVerficationFailure = (error) => {
  return {
    type: RESEND_CONFIRMATION_ERROR,
    payload: error,
  };
};

export const resendVerificationMail = (body) => {
  return (dispatch) => {
    dispatch(resendVerfication());
    //...
    RequestHandler
      .post(`/auth/resendVerificationMail`, body)
      .then(function (response) {
        dispatch(resendVerficationSuccess(response.data.message));
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          dispatch(resendVerficationFailure(JSON.stringify(error.response)));
        } else {
          dispatch(resendVerficationFailure("Network Err!"));
        }
      });
  };
};

export const userMailVerification = (body) => {
  return new Promise((resolve, reject) => {
    RequestHandler
      .patch(`/auth/user/verification`, body)
      .then(function (response) {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//login
const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    payload: user,
  };
};

const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

const loginFailure = (error) => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};

export const login = (user) => {
  return (dispatch) => {
    dispatch(loginUser(JSON.parse(user)));
    //...
    RequestHandler
      .post(`/auth/login`, JSON.parse(user))
      .then(function (response) {
        const { data } = response;
        const responseData = data && Object.keys(data).indexOf('data') > -1 ? data.data : data
        console.log("response ---------------", response);
        if (data && responseData.status === true) {
          localStorage.setItem("authUser", JSON.stringify(responseData.user));
          localStorage.setItem("token", responseData.token);
          dispatch(loginSuccess(responseData.user));
        } else {
          dispatch(loginFailure("Invalid login"));
        }
      })
      .catch(function (error) {
        console.log("error ------------", error.response);
        const { data } = error.response
        if (data) {
          dispatch(loginFailure(JSON.stringify(data)));
        } else {
          dispatch(loginFailure("Invalid login"));
        }
      });
  };
};

const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: true,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutUser());
    RequestHandler
      .post(`/auth/logout`, {})
      .then(function (response) {
        localStorage.removeItem("authUser"); 
        localStorage.removeItem("token"); 
        dispatch(logoutUserSuccess());
      })
      .catch((err) => {
        dispatch(apiError("Natwork Error! Failed to logout."));
      });
  };
};

const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

const registerUserBySocialAccount = (user, message) => {
  return {
    type: REGISTER_OAOUTH_USER,
    message: message,
    payload: user,
  };
};

export const socialLogin = (user) => {
  return (dispatch) => {
    dispatch(loginUser(user));
    // console.log("facebook login start");
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    RequestHandler
      .post(`/auth/socialLogin`, user, {
        headers: headers,
      })
      .then(function (response) {
        // console.log("facebook login success", response);
        localStorage.setItem("authUser", JSON.stringify(response.data.user));
        dispatch(loginSuccess(response.data.user));
      })
      .catch((error) => {
        console.log("error -------------", error, "\n", error.response);
        const { status } = error.response;
        if (status === 404) {
          dispatch(
            registerUserBySocialAccount(
              user,
              JSON.stringify(error.response.data)
            )
          );
        } else if (error.response && error.response.data) {
          dispatch(loginFailure(JSON.stringify(error.response.data.message)));
        } else {
          dispatch(loginFailure("Invalid login"));
        }
      });
  };
};

/**
 * Invite user actions ....
 */

const inviteUser = () => {
  return {
    type: INVITE_USER,
  };
};

const inviteSuccess = (msg) => {
  return {
    type: INVITE_SUCCESS,
    payload: msg,
  };
};

const inviteFailure = (error) => {
  return {
    type: INVITE_ERROR,
    payload: error,
  };
};

export const inviteUserPlayerTeam = (user) => {
  return (dispatch) => {
    dispatch(inviteUser(JSON.parse(user)));
    //auth/inviteUserTeam
    RequestHandler
      .post(`/auth/invitePlayerUser`, JSON.parse(user))
      .then(function (response) {
        dispatch(inviteSuccess(response.data.message));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          console.log("error1 ------------", error.response);
          dispatch(inviteFailure(error.response.data.message));
        }
      });
  };
};

export const inviteAdminUser = (user) => {
  return (dispatch) => {
    dispatch(inviteUser(JSON.parse(user)));
    //...
    RequestHandler
      .post(`/auth/inviteUser`, JSON.parse(user))
      .then(function (response) {
        dispatch(inviteSuccess(response.data.message));
      })
      .catch(function (error) {
        if ( error.response.data && error.response.data.message === "Invalid Token Input" ) {
          dispatch(signOutUser());
        } else {
          console.log("error1 ------------", error.response);
          dispatch(inviteFailure(error.response.data.message));
        }
      });
  };
};

/**
 * Forgot password ....
 */

const forgotPass = () => {
  return {
    type: FORGOT_PASS,
  };
};

const forgotSuccess = (msg) => {
  return {
    type: FORGOT_PASS_SUCCESS,
    payload: msg,
  };
};

const forgotFailure = (error) => {
  return {
    type: FORGOT_PASS_ERROR,
    payload: error,
  };
};

export const reqForgotPass = (body) => {
  // console.log("foroget");
  return (dispatch) => {
    dispatch(forgotPass());
    RequestHandler
      .post(`/auth/forgotPassword`, body)
      .then(function (response) {
        dispatch(forgotSuccess(response.data.message));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          console.log("error1 ------------", error.response);
          dispatch(forgotFailure(error.response.data.message));
        }
      });
  };
};

//change pass...
const changePass = () => {
  return {
    type: CHANGE_PASS,
  };
};

const changeSuccess = (msg) => {
  return {
    type: CHANGE_PASS_SUCCESS,
    payload: msg,
  };
};

const changeFailure = (error) => {
  return {
    type: CHANGE_PASS_ERROR,
    payload: error,
  };
};

export const reqChangePass = (body) => {
  // console.log("foroget", body);
  return (dispatch) => {
    dispatch(changePass());
    RequestHandler
      .post(`/auth/changePassword`, body)
      .then(function (response) {
        dispatch(changeSuccess(response.data.message));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          console.log("error1 ------------", error.response);
          dispatch(changeFailure(error.response.data.message));
        }
      });
  };
};

export const resetSTate = () => ({
  type: RESET_STATE,
});

// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER,
});

export const resetInviteModelState = () => {
  return (dispatch) => {
    dispatch(inviteFailure(""));
  };
};

export const inviteUserPlayer = (user) => {
  return (dispatch) => {
    dispatch(inviteUser(JSON.parse(user)));
    //...
    RequestHandler
      .post(`/auth/invitePlayerUser`, JSON.parse(user))
      .then(function (response) {
        dispatch(inviteSuccess(response.data.message));
      })
      .catch(function (error) {
        if (
          error.response.data &&
          error.response.data.message === "Invalid Token Input"
        ) {
          dispatch(signOutUser());
        } else {
          console.log("error1 ------------", error.response);
          dispatch(inviteFailure(error.response.data.message));
        }
      });
  };
};
