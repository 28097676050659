import React from 'react';
import { Redirect } from 'react-router-dom';

export const PrivateRoute = () => {
  let user = localStorage.getItem("authUser");
  if (!user) return (<Redirect to={'/'} />)
  user = JSON.parse(user);
  const { level, _id, organization_id, institution_id, team_id, family_id  } = user;
  // ... Admin @level 1000
  if (level === 1000) return (<Redirect to={'/dashboard'} />)
  // ... Organization Admin @level 300
  if (level === 300) return (<Redirect to={`/institutions/${organization_id}`} />)
  // ... Institution Admin @level 250
  if (level === 250) return (<Redirect to={`/teams/${institution_id}`} />)
  // ... Team Admin @level 200
  if (level === 200) return (<Redirect to={`/team/players/${team_id}`} />)
  // ... player  @level 100
  // if (level === 100) return (<Redirect to={`/player/dasboard/${user._id}`} />)
  if (level === 100) return (<Redirect to={`/player/welcome/${_id}`} />)
   

  if (level === 150) return (<Redirect to={`/family/${family_id}`} />)

  return (
    <Redirect to={'/dashboard'} />
  )

}