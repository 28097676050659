
import {
  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL,

  GET_DATA_SECURITY,
  GET_DATA_SECURITY_SUCCESS,
  GET_DATA_SECURITY_FAIL,

  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,

  GET_SENSOR_INFORMATION,
  GET_SENSOR_INFORMATION_SUCCESS,
  GET_SENSOR_INFORMATION_FAILED,

  GET_SENSOR_EVENTS,
  GET_SENSOR_EVENTS_SUCCESS,
  GET_SENSOR_EVENTS_FAILED
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";

import RequestHandler from '../../helpers/RequestHandler'
// export const getUsers = () => ({
//   type: GET_USERS,
// })

// export const getUsersSuccess = users => ({
//   type: GET_USERS_SUCCESS,
//   payload: users,
// })

// export const getUsersFail = error => ({
//   type: GET_USERS_FAIL,
//   payload: error,
// })

const getSensor = () => ({
  type: GET_SENSOR,
})

const getSensorSuccess = item => ({
  type: GET_SENSOR_SUCCESS,
  payload: item,
})

const getSensorFail = error => ({
  type: GET_SENSOR_FAIL,
  payload: error,
})

const getSensorEvents = () => ({
  type: GET_SENSOR_EVENTS
})

const getSensorEventsSuccess = (payload) => ({
  type: GET_SENSOR_EVENTS_SUCCESS,
  payload
})

const getSensorEventsFailed = (payload) => ({
  type: GET_SENSOR_EVENTS_FAILED,
  payload
})

export const getSensorDeviceEvents = (team_id, sensor_id, params = {}) => {
  return dispatch => {
    dispatch(getSensorEvents())
    let apiURL = `/sensor/events/${team_id}/${sensor_id}`;
    const urlParams = new URLSearchParams(params).toString();
    if (urlParams.length > 0) {
      apiURL += `?${urlParams}`
    } 
    RequestHandler.get(apiURL)
      .then((response) => response.data.data)
      .then((response) => {
        dispatch(getSensorEventsSuccess(response))
      })
      .catch((e) => {
        const { response } = e
        if (response){
          const { data } = response
          if (data) {
            const { message } = data
            if (message === "Invalid Token Input") {
              dispatch(signOutUser())
            } else {
              dispatch(getSensorEventsFailed(message))
            }
          }
        } else {
          dispatch(getSensorEventsFailed('Something went wrong.'))
        }
      })
  }
}

export const getAllMpsVsTimeData = (body) => {
  return new Promise((resolve, reject) => {
    RequestHandler.post("/sensor/getAllMpsVsTimeData", body)
    .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}

const getDataSecurity = () => ({
  type: GET_DATA_SECURITY,
})

const getDataSecuritySuccess = item => ({
  type: GET_DATA_SECURITY_SUCCESS,
  payload: item,
})

const getDataSecurityFail = error => ({
  type: GET_DATA_SECURITY_FAIL,
  payload: error,
})
export const getDataSecurityTable = () => {
  return dispatch => {
    dispatch(getDataSecurity());
    RequestHandler.get("/player/getDataSecurityTable")
     .then(function (response) {
        dispatch(getDataSecuritySuccess(response.data.data));
      })
      .catch(function (error) {
        console.log('error getDataSecurityTable----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getDataSecurityFail(JSON.stringify(error.response.data.message)));

        }
      });
  }
}

export const singleDataSecurityTable = (body) => {
  return new Promise((resolve, reject) => {
    RequestHandler.post("/player/singleDataSecurity", body)
      .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
		    console.log("error",error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}

export const getAllsensors = () =>{
  return dispatch => {
    dispatch(getSensor());
    RequestHandler.get("/sensor/getSensor")
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      dispatch(getSensorSuccess(response.data.data));

    })
    .catch(function (error) {
      console.log('error ----------------', error);
      if (error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getSensorFail(JSON.stringify(error.response.data.message)));

      }
     
    });
  }

}
export const getCompleteSimulation = (body) => {
    return new Promise((resolve, reject) => {
    RequestHandler.post("/sensor/getCompleteSimulation", body)
     .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getAllCompleteSimulation = (body) => {
    return new Promise((resolve, reject) => {
    RequestHandler.post("/sensor/getAllCompleteSimulation", body)
     .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getfailedSimulation = (body) => {
    return new Promise((resolve, reject) => {
    RequestHandler.post("/sensor/getfailedSimulation", body)
     .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
export const getpendingSimulation = (body) => {
    return new Promise((resolve, reject) => {
    RequestHandler.post("/sensor/getpendingSimulation", body)
     .then(function (response) {
        resolve(response) 
      })
      .catch(function (error) {
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          reject("Invalid Token")
        } else {
          reject("Somthing wrong");
        }
      });
  })
}
// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})