import { lazy } from "react"
import { Redirect } from "react-router-dom"

import { PrivateRoute } from "./middleware/privateRoute"

// Dashboard
// const Dashboard = lazy(() => import("../pages/Dashboard/index"))
const OrganizationTab = lazy(() => import("../pages/Organization/index"))
// const Organizationstaff = lazy(() => import("../pages/Organizationstaff/index"))
const Invite = lazy(() => import("../pages/Invite/index"))
const Edit = lazy(() => import("../pages/Edit/index"))

const InstitutionsTab = lazy(() => import("../pages/Institutions/index"))
const InstitutionsDash = lazy(() => import("../pages/Institutions/Dashboard/index"))

const TeamdashboardTab = lazy(() => import("../pages/Teamdashboard/index.js"))
const TeamdashboardOldTab = lazy(() => import("../pages/Teamdashboard/index-old.js"))
const teamdashboard = lazy(() => import("../pages/Teamdashboard/Dashboard/index"))

const PlayerslistTab = lazy(() => import("../pages/Playerslist/index"))
const Brain = lazy(() => import("../pages/Brain/index"))
const Brainupload = lazy(() => import("../pages/SimulationUpload/index"))
// const Brainremove = lazy(() => import("../pages/Brainremove/index"))
// const Profile = lazy(() => import("../pages/Profile/index"))
const ProfilecomponentView = lazy(() => import("../pages/Profile/deviceDetect"))
const Teamanalytics = lazy(() => import("../pages/Teamanalytics/index"))
// const PlayerDash = lazy(() => import("../pages/PlayerDash/index"))
const Braindetails = lazy(() => import("../pages/Braindetails/index"))
const SimulationDetails = lazy(() => import("../pages/Braindetails/simulation_details"))
const Playerdasboard = lazy(() => import("../pages/Playerdasboard/index"))

// staff
const Staff = lazy(() => import("../pages/Staff/adminStaff"))
const orgStaff = lazy(() => import("../pages/Staff/organizationStaff"))
const institutionStaff = lazy(() => import("../pages/Staff/institutionStaff"))
const teamStaff = lazy(() => import("../pages/Staff/teamStaff"))
// const TeamStats = lazy(() => import("../pages/TeamStats/index"))
const TeamStatsNew = lazy(() => import("../pages/TeamStats/indexNewtop20"))
const TeamStatsOptimized = lazy(() => import("../pages/TeamStatsOptimized"))

const CompleteSimulation = lazy(() => import("../pages/CompleteSimulation"))
const PendingSimulation = lazy(() => import("../pages/PendingSimulation"))
const FailedSimulation = lazy(() => import("../pages/FailedSimulation"))
// const OrgStats = lazy(() => import("../pages/OrgStats/index"))
const OrgStats = lazy(() => import("../pages/OrgStats/indexOrgTop20"))
// const InstitutionsStats = lazy(() => import("../pages/InstitutionsStats/index"))
const InstitutionsStatsNew = lazy(() => import("../pages/InstitutionsStats/indexNewTop20"))
const SimulationLog = lazy(() => import("../pages/SimulationLog/index"))
const UndefinedPlayers = lazy(() => import("../pages/Staff/undefiendPlayers"))
const teamSimsLogs = lazy(() => import("../pages/SimLog/index"))
const FamilyDash = lazy(() => import("../pages/Family/Dashboard/index"))
const ToolKit = lazy(() => import("../pages/ToolKit/index"))
const ToolKitPage = lazy(() => import("../pages/ToolKit/main"))

const FamilyStaff = lazy(() => import("../pages/Staff/familyStaff"))
//const nullStaff = lazy(() => import("../pages/Staff/nullStaff"))
const PlayerWelcome = lazy(() => import("../pages/PlayerWelcome/index"))

// const sensorStaff = lazy(() => import("../pages/Staff/sensorStaff"))
// const VerifyOTP = lazy(() => import("../pages/Authentication/OTP/index"))
const DataCollection = lazy(() => import('../pages/DataCollection'))
// const componentView = lazy(() => import("../pages/PlayerDashMob/mobileView"))
const PlayerDashNew = lazy(() => import("../pages/PlayerDashMob/mobileViewNew"))

const BrainRegions = lazy(() => import("../pages/PlayerDashMob/BrainRegions"))

const GenerateCustomMesh = lazy(() => import ("../pages/Home/GenerateCustomMesh"))
const DataAnalytics = lazy(() => import("../pages/Organization/data-analytics"))

const DataSecurity = lazy(() => import("../pages/DataSecurity"))
const Details = lazy(() => import("../pages/DataSecurity/details"))
const SensorDeviceDashboard = lazy(() => import("../pages/SensorDevice/Dashboard"))
const ExportData = lazy(() => import("../pages/ExportData"))
const ManageFamily = lazy(() => import("../pages/ManageFamily"))


const userRoutes = [
  // this route should be at the end of all other routes
  // { path: "/dashboard", component: Dashboard },

  { path: "/user/dashboard", component: PrivateRoute, userAccessLevel: 100 },
  { path: "/invite/user/:type/:id?", component: Invite, userAccessLevel: 200 },
  { path: "/edit", component: Edit },

  { path: "/sensor/dashboard/:team_id/:sensor_id", component: SensorDeviceDashboard },
  { path: "/admin/staff", component: Staff, userAccessLevel: 1000 },
  { path: "/dashboard", component: OrganizationTab, userAccessLevel: 1000 },
  { path: "/admin/data-analytics", component: DataAnalytics, userAccessLevel: 1000 },
  { path: "/organization/staff/:id?", component: orgStaff, userAccessLevel: 300 },
  { path: "/players/undefined", component: UndefinedPlayers, userAccessLevel: 1000 },
  { path: "/institutions/:org_id", component: InstitutionsDash, userAccessLevel: 300 },
  { path: "/orgStats/:_id?", component: OrgStats, userAccessLevel: 300 },

  { path: "/tab/institutions", component: InstitutionsTab, userAccessLevel: 1000 },
  { path: "/institution/staff/:id?", component: institutionStaff, userAccessLevel: 250 },
  // { path: "/institutionsStats/:_id", component: InstitutionsStats, userAccessLevel: 100 },
  { path: "/institutionsStats/:_id", component: InstitutionsStatsNew, userAccessLevel: 100 },


  { path: "/teams/:ins_id", component: teamdashboard, userAccessLevel: 200 },
  { path: "/team/players/:team_id", component: TeamdashboardTab, userAccessLevel: 200 },
  { path: "/team/players-old/:team_id", component: TeamdashboardOldTab, userAccessLevel: 200 },
  { path: "/team/staff/:id?", component: teamStaff, userAccessLevel: 200 },
  // { path: "/family/staff/:_id", component: FamilyStaff, userAccessLevel: 200 },
  { path: "/family/staff/:_id", component: ManageFamily , userAccessLevel: 200 },

  { path: "/tab/playerslist", component: PlayerslistTab, userAccessLevel: 200 },
  { path: "/simulation/select-sensor/:team_id", component: Brain },
  { path: "/simulation/upload/:team_id", component: Brainupload },
  // { path: "/brainremove", component: Brainremove },
  // { path: "/profile/:_id?/:tab?", component: Profile },
  { path: "/profile/:_id?/:tab?", component: ProfilecomponentView },

  { path: "/teamanalytics", component: Teamanalytics },
  // { path: "/player/dashboard/:_id", component: PlayerDash }, // old one 

  // { path: "/player/dashboard/:_id", component: componentView }, // current commented
  { path: "/player/dashboard/:_id", component: PlayerDashNew },// new
  // { path: "/sensor/dashboard/:id", component: }

  { path: "/player/welcome/:_id", component: PlayerWelcome},
  { path: "/player/details/:event_id", component: Braindetails },
  { path: "/simulation/details/:event_id", component: SimulationDetails },
  { path: "/player/dasboard2", component: Playerdasboard, userAccessLevel: 100 },
  // { path: "/teamstats/:_id?", component: TeamStats, userAccessLevel: 200 },
  { path: "/teamstats/:_id?", component: TeamStatsNew, userAccessLevel: 200 },
  // { path: "/teamstats_new/:_id?", component: TeamStatsNew, userAccessLevel: 200 },
  { path: "/teamstats_optimized/:_id?", component: TeamStatsOptimized, userAccessLevel: 200 },

  { path: "/completeSimulation/:type/:_id?", component: CompleteSimulation },
  { path: "/failedSimulation/:type/:_id?", component: FailedSimulation },
  { path: "/pendingSimulation/:type/:_id?", component: PendingSimulation },

  { path: "/simulation/log/:event_id", component: SimulationLog, userAccessLevel: 100 },
  { path: "/sim/log/:team_id", component: teamSimsLogs, userAccessLevel: 100 },
  { path: "/family/:_id", component: FamilyDash, userAccessLevel: 150 },
  // { path: "/staff/:_id?", component: FamilyStaff, userAccessLevel: 150 },
  { path: "/inviting/user/:type/:id?", component: Invite, userAccessLevel: 150 },
  { path: "/ml/toolkit/:type/:_id?", component: ToolKitPage, userAccessLevel: 100 },
  { path: "/ml/v2/toolkit/:type/:_id?", component: ToolKit, userAccessLevel: 100 },

  { path: "/buy-mesh", component: GenerateCustomMesh},

  { path: "/player/data-collection/:_id", component: DataCollection},
  //{ path: "/null-staff", component: nullStaff},
  {path:"/player/brain-regions",component:BrainRegions},

  {path:"/data-security",  exact: true, component: DataSecurity, userAccessLevel: 1000},
  {path:"/compliance/:type", component: Details, userAccessLevel: 1000},
  {path: "/export-data/:type/:id", component: ExportData},
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
 
 

]

export default userRoutes;
