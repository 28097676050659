import RequestHandler from '../../helpers/RequestHandler'
import _ from 'lodash'
import {
  GET_INSTITUTIONS_LIST,
  GET_INSTITUTIONS_LIST_SUCCESS,
  GET_INSTITUTIONS_LIST_FAIL,

  GET_ORG_INSTITUTIONS_LIST,
  GET_ORG_INSTITUTIONS_LIST_SUCCESS,
  GET_ORG_INSTITUTIONS_LIST_FAIL,

  GET_INSTITUTIONS_BY_ORG,
  GET_INSTITUTIONS_BY_ORG_SUCCESS,
  GET_INSTITUTIONS_BY_ORG_FAIL,

  INS_STAFF,
  INS_STAFF_SUCCESS,
  INS_STAFF_FAIL,
  ADD_FAMILY,
  ADD_INSTITUTION,
  RENAME_INSTITUTION,
  DELETS_INSTITUTION,
  EDIT_INSTITUTION,
  EDIT_INSTITUTION_STATE,
  EDIT_INSTITUTION_SUCCESS,
  EDIT_INSTITUTION_FAIL,
  EPMTY_EDITING_DATA,
  MERGE_INSTITUTION,

  INS_BYID,
  INS_BYID_SUCCESS,
  INS_BYID_FAIL,

  GET_INS_STAT_SUMMARY,
  GET_INS_STAT_SUMMARY_SUCCESS,
  GET_INS_STAT_SUMMARY_FAIL,

  SET_INS_ANYLSIS_DATA,

  SET_INS_MPS_DATA,

  GET_INS_SIMULATIONS_SUCC,
  GET_INS_SIMULATIONS_FAIL,

  DELETE_INS_STAFF_STATE,
  DELETE_INS_STAFF_FAIL,
  DELETE_INS_STAFF_LOADER,
  DELETE_INS_STAFF_AFTER_SUCC,

  //STRAIN
  GET_ALL_TEAM_ANALYSIS_DATA,
  GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS,
  GET_ALL_TEAM_ANALYSIS_DATA_FAIL,

  //PRESSURE
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE,
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS,
  GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL,
} from "./actionTypes"
import { LOGOUT_LOGGEDIN_USER } from "../auth/login/actionTypes";

export const setInsAnylsisData = (items) => ({
  type: SET_INS_ANYLSIS_DATA,
  payload: items,
})


const getInsSimulationsSuccess = items => ({
  type: GET_INS_SIMULATIONS_SUCC,
  payload: items,
})

const getInsSimulationsFail = error => ({
  type: GET_INS_SIMULATIONS_FAIL,
  payload: error,
})

export const fetchInstitutionSimulations = (body) => {
  return dispatch => {

  const { insTeams } = body;
  // var data = [];
  // var fetches = [];

  /* for(let j = 0; j< insTeams.length; j++)
  {

    var fetche = RequestHandler.patch("/player/fetchListByTeam", { team_id: insTeams[j].team_id._id, itemPerPage: 1000})
    .then(function (response) {
      var players = response.data.data.players;
      players.forEach(player => {
        data = data.concat(player.sensor_details);
      });
    })
    fetches.push(fetche);


    } */
    const fetches = insTeams.teams.map((el) => {
      return RequestHandler.patch("/player/fetchListByTeam", { team_id: el._id, itemPerPage: 1000})
        .then((response) => response.data.data)
        .then((response) => {
          const { players } = response
          return _.flattenDeep(
            players.map((innerEl) => innerEl.players.map((innerItm) => innerItm.sensor_details))
          ).filter((el) => el);
          // return _.flattenDeep(players.map((innerEl) => innerEl.sensor_details)).filter((el) => el)
        })
        .catch((e) => {
          console.log(e);
          return null
        })
    })

    Promise.all(fetches).then((data) => {
      const simulationsData = data.reduce((el, ac) => [...el, ...ac])
      dispatch(getInsSimulationsSuccess(simulationsData));
    }).catch(function (error) {
      console.log('error ----------------', error);
      if (error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      } else {
        dispatch(getInsSimulationsFail(JSON.stringify(error.response.data)));
      }
    });

  }

}

/* FETCH STATE SUMMARY DATA */
const getInsSTatsSummary = () => ({
  type: GET_INS_STAT_SUMMARY,
})

const getInsSTatsSummarySuccess = items => ({
  type: GET_INS_STAT_SUMMARY_SUCCESS,
  payload: items,
})

const getInsSTatsSummaryFail = error => ({
  type: GET_INS_STAT_SUMMARY_FAIL,
  payload: error,
})

export const fetchInsSTatsSummaryData = (body) => {
  return dispatch => {
    dispatch(getInsSTatsSummary());

    RequestHandler.patch("/institution/stats/summary", body)
      .then(function (response) {
        // console.log(response)
        dispatch(getInsSTatsSummarySuccess(response.data.data));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getInsSTatsSummaryFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

export const setInsMpsData = (items) => ({
  type: SET_INS_MPS_DATA,
  payload: items,
})

/**
 * Fetching Single instititutions details by id...
 */
const insById = () => ({
  type: INS_BYID,
})

const insByIdSuccess = (items) => ({
  type: INS_BYID_SUCCESS,
  payload: items
})

const insByIdFail = (err) => ({
  type: INS_BYID_FAIL,
  payload: err
})

export const getInstituteDeatilsById = (body) => {
  return dispatch => {
    dispatch(insById());
    RequestHandler.patch("/institution/details/byId", body)
      .then(function (response) {
        dispatch(insByIdSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(insByIdFail(JSON.stringify(error.response ? error.response.data.message : 'Failed to load')));
        }
      });
  }
}

// addFamily
export const addFamily = (ins) => ({
  type: ADD_FAMILY,
  payload: ins,
})
/**
 * Edit functionality start here..
 * @returns 
 */

// add ...
export const addInstitutions = (ins) => ({
  type: ADD_INSTITUTION,
  payload: ins,
})
// Rename ...
export const renameInstitutions = (item) => ({
  type: RENAME_INSTITUTION,
  payload: item,
})
// Delete ...
export const deleteInstitutions = (item) => ({
  type: DELETS_INSTITUTION,
  payload: item,
})
//merge ...
export const margeInstitutions = (item) => (
  {
    type: MERGE_INSTITUTION,
    payload: item,
  })


// edit -
const editins = () => ({
  type: EDIT_INSTITUTION
})

const editState = (state) => ({
  type: EDIT_INSTITUTION_STATE,
  payload: state
})

const editInsFail = (msg) => ({
  type: EDIT_INSTITUTION_FAIL,
  payload: msg
})

const editInsSuccess = (msg) => ({
  type: EDIT_INSTITUTION_SUCCESS,
  payload: msg
})


// Reseting all edit state ...

export const emptyInstitutionEditingState = () => ({
  type: EPMTY_EDITING_DATA
})



// Make edit api calls ...
export const editInstituions = (items) => {
  return async dispatch => {
    dispatch(editins());
    try {
      //.add
      /* if (items.addInsName) {
        dispatch(editState('Adding Institution...'));
        await addInsTodb(items.addInsName, items.organization_id);
        dispatch(editState('Institution added successfully.'));
      } */
      if (items.addList && items.addList.length > 0) {
        dispatch(editState('Adding Institution...'));
        const addPromises = items.addList.map((el) => addInsTodb(el, items.organization_id))
        await Promise.all(addPromises)
        dispatch(editState('Institution added successfully.'));
      }
      // //.rename
      if (items.renameInsList && items.renameInsList.length > 0) {
        dispatch(editState('Updating Institutions name...'));
        await renameInsTodb(items.renameInsList);
        dispatch(editState('Institutions name updated.'));

      }

      if (items.deleteInsList && items.deleteInsList.length) {
        dispatch(editState('Deleteing Institutions...'));
        await deleteInsTodb(items.deleteInsList);
        dispatch(editState('Institutions Deleted.'));
      }
      // // merge
      if (items.mergeInsList && items.mergeInsList.length) {
        // console.log("mergeInsList", items.mergeInsList);
        dispatch(editState('merging Institutions...'));
        await deleteInsTodb(items.mergeInsList);
        dispatch(editState('Institutions merged.'));
      }
      dispatch(editInsSuccess('Successfully edited Institutions.'))
    } catch (error) {
      //  console.log('catch err -------------------\n', error)
      if (error.response === undefined && (error.message === undefined || !error.message)) dispatch(editInsFail('Network Error.'))
      if (error.response !== undefined && error.response.data !== undefined && error.response.data && error.response.data.message === "Invalid Token Input") {
        dispatch(signOutUser())
      }
      // dispatch(editOrgFail(err.response));
      if (error.message !== undefined && error.message) {
		    dispatch(editInsFail(error.message))
	    }
    }
  }
}

const deleteInsTodb = (institutions) => {
  // console.log("ins", institutions);
  return new Promise((resolve, reject) => {
    RequestHandler.delete("/institution/delete", { data: { institutions }, headers: { "Authorization": "***" } })
      .then(function (response) {
        //   console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //  console.log('error ----------------', error);
        reject(error.response.data);
      });
  })
}

const renameInsTodb = (institutions) => {
  return new Promise((resolve, reject) => {
    RequestHandler.put("/institution/rename", { institutions })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('error ----------------', error);
        reject(error);
      });
  })
}

const addInsTodb = (name, organization_id) => {
  return new Promise((resolve, reject) => {
    RequestHandler.post("/institution/add", { name: name, organization_id: organization_id })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  })
}

//@ edit end ...

/**
 * Fetching list of organization institutions
 * @returns list
 * 
 */
const getInstitutionsList = () => ({
  type: GET_INSTITUTIONS_LIST,
})

const getInstitutionsListSuccess = item => ({
  type: GET_INSTITUTIONS_LIST_SUCCESS,
  payload: item,
})

const getInstitutionsListFail = error => ({
  type: GET_INSTITUTIONS_LIST_FAIL,
  payload: error,
})

export const getAllInstitutions = () => {
  return dispatch => {
    dispatch(getInstitutionsList());
    RequestHandler.get("/institution/getInstitution")
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(getInstitutionsListSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getInstitutionsListFail(JSON.stringify(error.response.data.message)));
        }

      });
  }
}
export const getSingleIns = (body) => {
  return new Promise((resolve, reject) => {
    RequestHandler.patch("/institution/single/Ins", body)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      });
  })
}

// GET ORG INS. LIST
const getOrgInstitutionsList = (org_id) => ({
  type: GET_ORG_INSTITUTIONS_LIST,
  payload: org_id
})

const getOrgInstitutionsListSuccess = item => ({
  type: GET_ORG_INSTITUTIONS_LIST_SUCCESS,
  payload: item,
})
const getOrgInstitutionsListFail = error => ({
  type: GET_ORG_INSTITUTIONS_LIST_FAIL,
  payload: error,
})

const getInstitutionsByOrg = item => ({
  type: GET_INSTITUTIONS_BY_ORG,
  payload: item,
})
const getInstitutionsByOrgSuccess = item => ({
  type: GET_INSTITUTIONS_BY_ORG_SUCCESS,
  payload: item,
})
const getInstitutionsByOrgFail = item => ({
  type: GET_INSTITUTIONS_BY_ORG_FAIL,
  payload: item,
})

export const getOrgInstitutions = (org_id) => {
  return dispatch => {
    dispatch(getOrgInstitutionsList(org_id));
    RequestHandler.patch("/institution/getOrgInstitutions", { org_id })
      .then(function (response) {
        dispatch(getOrgInstitutionsListSuccess(response.data.data));
      })
      .catch(function (error) {
        if (error && error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getOrgInstitutionsListFail(JSON.stringify(error.response.data.message)));
        }
      });

  }
}
export const getInstitutionsByOrgId = (org_id) => {
  return dispatch => {
    dispatch(getInstitutionsByOrg(org_id));
    RequestHandler.patch("/institution/getInstitutionsByOrgId", { org_id })
      .then(function (response) {
        dispatch(getInstitutionsByOrgSuccess(response.data.data));

      })
      .catch(function (error) {
        console.log('error ----------------', error);
        console.log('error.response.data.message ----------------', error.response.data.message);
        if (error && error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getInstitutionsByOrgFail(JSON.stringify(error.response.data.message)));
        }
      });

  }
}

/**
 * Fetching institution staff...
 */
const insStaff = (state) => ({
  type: INS_STAFF,
})

const insStaffSuccess = (items) => ({
  type: INS_STAFF_SUCCESS,
  payload: items
})

const insStaffFail = (err) => ({
  type: INS_STAFF_FAIL,
  payload: err
})

export const getInsStaffList = (body) => {
  return dispatch => {
    dispatch(insStaff());

    RequestHandler.patch("/institution/getStaffList", body)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        dispatch(insStaffSuccess(response.data.data));
      })
      .catch(function (error) {
        // console.log('error ----------------', error);
        if (error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(insStaffFail(JSON.stringify(error.response.data.message)));
        }
      });
  }
}

/* INS STAFF DELETE**/
const deleteInsStaffState = (msg) => ({
  type: DELETE_INS_STAFF_STATE,
  payload: msg
})

const deleteInsStaffloader = () => ({
  type: DELETE_INS_STAFF_LOADER
})
const deleteInsStaffAfterSucc = () => ({
  type: DELETE_INS_STAFF_AFTER_SUCC
})

const deleteInsStaffFail = (msg) => ({
  type: DELETE_INS_STAFF_FAIL,
  payload: msg
})
export const deleteInsStaffData = (items) => {
  return async dispatch => {
    dispatch(deleteInsStaffloader());
    // console.log('items -----------------', items)
    // console.log('deleteInsStaffData', items.deleteInsStaff)
    try {

      if (items.deleteInsStaff && items.deleteInsStaff.length > 0) {
        dispatch(deleteInsStaffState('Ins staff deleting...'));
        await deleteInsStaffFromdb(items.deleteInsStaff);
        dispatch(deleteInsStaffState('Ins staff deleted successfully'));
        setTimeout(() =>
          dispatch(deleteInsStaffAfterSucc())
          , 1000)
      }
    } catch (error) {
      //  console.log('catch err -------------------\n', error)
      if (error.response === undefined) return dispatch(deleteInsStaffFail('Network Error.'))
      if (error.response.data && error.response.data.message === "Invalid Token Input") {
        return dispatch(signOutUser())
      }

      return dispatch(deleteInsStaffFail(JSON.stringify(error.response)));
    }
  }
}

const deleteInsStaffFromdb = (user_id) => {
  return new Promise((resolve, reject) => {
    RequestHandler.patch("/institution/deleteInsStaff", { user_id })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        //  console.log('error ----------------', error);
        reject(error.response.data);
      });
  })
}

/*END HERE**/
// Signout user if token invalid
const signOutUser = () => ({
  type: LOGOUT_LOGGEDIN_USER
})

// new analysis data for strain============
const getAllPlayerAnalysisData = () => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA
})

const getAllPlayerAnalysisDataSuccess = (payload) => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA_SUCCESS,
  payload
})

const getAllPlayerAnalysisDataFail = (payload) => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA_FAIL,
  payload
})

export const fetchInsTop20Events = (data) => {
  let id = data.id;
  let pressure_dashboard = false;
  return dispatch => {
    dispatch(getAllPlayerAnalysisData())
    
    RequestHandler.get(`/institution/getInsMpsData/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        dispatch(getAllPlayerAnalysisDataSuccess(response));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerAnalysisDataFail(JSON.stringify(error.response.data)));
        }
      });
  }
}

const getAllPlayerAnalysisDataPressure = () => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE
})
const getAllPlayerAnalysisDataPressureSuccess = (payload) => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_SUCCESS,
  payload
})
const getAllPlayerAnalysisDataPressureFail = (payload) => ({
  type: GET_ALL_TEAM_ANALYSIS_DATA_PRESSURE_FAIL,
  payload
})
export const fetchInsTop20EventsPressure = (data) => {
  let id = data.id;
  let pressure_dashboard = true
  return dispatch => {
    dispatch(getAllPlayerAnalysisDataPressure())    
    RequestHandler.get(`/institution/getInsMpsData/${id}/${data.type}/${pressure_dashboard}`)
      .then((response) => response.data.data)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        dispatch(getAllPlayerAnalysisDataPressureSuccess(response));
      })
      .catch(function (error) {
        console.log('error ----------------', error);
        if (error.response && error.response.data && error.response.data.message === "Invalid Token Input") {
          dispatch(signOutUser())
        } else {
          dispatch(getAllPlayerAnalysisDataPressureFail(JSON.stringify(error.response.data)));
        }
      });
  }
}