import React, { Component } from "react"
import {
Modal,
Button
} from "react-bootstrap"
import { Alert } from "reactstrap"
import RequestHandler from '../../helpers/RequestHandler'
import "./checkout.scss";

/*
Props: price and discount
*/
class checkoutPop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      couponCode: ''
    }
    this.handleStripeCheckout = this.handleStripeCheckout.bind(this)
    // this.handleApplyCoupon = this.handleApplyCoupon.bind(this)
  }

  handleStripeCheckout() {
    RequestHandler.post('/api/stripe-checkout', {
      price_id: this.props.selectedPricePlan
    }).then((response) => response.data.data)
    .then((res) => {
      // console.log(res)
      const {redirect_url} = res
      if (redirect_url) {
        window.location.href = redirect_url
      }
    }).catch((err) => console.log(err))
  }

  handleApplyCoupon = () => {
    if (!this.state.couponCode || this.state.couponCode.trim().length === 0) return
    // console.log(this.props)
    if (typeof this.props.applyDiscountHandler === 'function') {
      this.props.applyDiscountHandler(this.state.couponCode)
    }
  }

  resetCoupon = () => {
    this.setState({
      couponCode: ''
    })
  }

  render() {
    const { price, couponDetails, verifyCouponError } = this.props
    const priceValue = price ? parseFloat(price) : 0
    let discountType = 'amount'
    const couponExists = couponDetails && Object.keys(couponDetails).length > 0 ? true : false
    let discountValue = couponExists && Object.keys(couponDetails).indexOf('amount_off') > -1 && couponDetails.amount_off ? parseFloat(couponDetails.amount_off / 100) : null
    if (!discountValue) {
      const percent_off = couponExists && Object.keys(couponDetails).indexOf('percent_off') > -1 && couponDetails.percent_off ? parseFloat(couponDetails.percent_off) : 0
      /* if (percent_off > 0) {
        discountType = 'amount'
      } */
      discountValue = percent_off > 0 ? parseFloat((priceValue * percent_off) / 100) : 0
    }
    const couponValid = couponExists && Object.keys(couponDetails).indexOf('valid') > -1 ? couponDetails.valid : true
    const totalValue = (parseFloat(priceValue) - discountValue).toFixed(2)
    return (
      <React.Fragment>
        <Modal
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checkout-model"
          onHide={() => true}
        >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Checkout
            </Modal.Title>
            <Button onClick={()=> {
              this.setState({couponCode: ''})
              this.props.onHide('Checkout')
            }}>
                <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ul>
                    <li>Price <span>${priceValue} <span>USD</span></span></li>
                    <li>Discount <span>${discountValue} <span>USD</span></span></li>
                    <li>Total Price <span>${totalValue} <span>USD</span></span></li>
            </ul>
            <div>
              {verifyCouponError ? (
                <Alert color="danger">{verifyCouponError}</Alert>
              ) : null}
              {!couponValid ? (
                <Alert color="danger">Its not a valid coupon. Please try other.</Alert>
              ) : null}
              {couponExists && couponValid ? (
                <Alert color="success">Congratulations. You saved {discountType === 'amount' ? '$' : null}{discountValue} USD.</Alert>
              ) : null}
            </div>
            <form>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Have a coupon code?" id="coupon-code" value={this.state.couponCode} onChange={(e) => this.setState({
                  couponCode: e.target.value
                })}/>
              </div>
              <button type="button" onClick={this.handleApplyCoupon} disabled={this.props.verifyCoupon}>Apply</button>
            </form>
            {/* <Button><img src="/paypal.png" alt="img"/></Button>
            <Button><img src="/debit.png" alt="img"/></Button> */}
            <Button onClick={() => this.props.onCard({
              couponCode: this.state.couponCode
            })} className="pay-button" disabled={this.props.verifyCoupon}>Pay</Button>
            <p>Powered by <img src="/stripe-logo.png" alt="img" style={{ maxWidth: '65px'}}/></p>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}


export default checkoutPop
