import axios from 'axios';

// Create an Axios instance
const RequestHandler = axios.create();

// Add a request interceptor
RequestHandler.interceptors.request.use(
    (config) => {
        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        if (token) {
            // Add the token to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

export default RequestHandler;